import React from "react";
import classnames from "classnames";
import { InView } from "react-intersection-observer";

export function ScrollAppear({ direction, children }) {
  return (
    <InView>
      {({ inView, ref }) => (
        <div ref={ref}>
          <div
            className={classnames("scroll-appear", direction, {
              "is-visible": inView,
            })}
          >
            {children}
          </div>
        </div>
      )}
    </InView>
  );
}
