const socials = [
  {
    label: "github",
    href: "https://github.com/tomdaniels",
  },
  {
    label: "linkedin",
    href: "https://www.linkedin.com/in/tom-daniels-0289a7b6",
  },
  {
    label: "soundcloud",
    href: "https://soundcloud.com/tommydaniels7",
  },
];

const CURRENT = Object.freeze({
  COMPANY: "picklebet",
  PRODUCT_PAGE: "https://picklebet.com/",
});

export { CURRENT, socials };
