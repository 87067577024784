export function Links({ links }) {
  function renderLink({ label, href }) {
    return (
      <li key={`${label}-link`} className={label}>
        <a target="_blank" rel="noopener" href={href}></a>
      </li>
    );
  }

  return <ul>{links.map(renderLink)}</ul>;
}
