import { useEffect, useState } from "react";

import { useInView } from "react-intersection-observer";

import { excuses } from "../constants";

export function Excuse() {
  const [excuse, setExcuse] = useState("");
  const { inView, ref } = useInView();

  function getRandom(list) {
    return list[Math.floor(Math.random() * list.length)];
  }

  useEffect(() => {
    if (inView) {
      setExcuse(getRandom(excuses));
    } else {
      setExcuse("");
    }
  }, [inView]);

  return (
    <pre ref={ref} key={excuse}>
      {excuse}
    </pre>
  );
}
