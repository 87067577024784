export function Tag({ company, href }) {
  return (
    <pre>
      software engineer @{" "}
      <a target="_blank" rel="noopener" href={href}>
        {company}
      </a>
    </pre>
  );
}
