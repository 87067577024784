export const FONTS = [
  {
    family: "Press Start 2P",
    weight: "700",
  },
  {
    family: "Rubik",
    weigth: "*",
  },
];
