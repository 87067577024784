import useFontFaceObserver from "use-font-face-observer";
import { CURRENT, FONTS, socials } from "../constants";
import { Tag, Links, Excuse, ScrollAppear } from "./export";

export function App({ name }) {
  const isFontLoaded = useFontFaceObserver(FONTS);

  if (!isFontLoaded) {
    return (
      <section>
        <div className="loader"></div>
      </section>
    );
  }

  return (
    <div className="page">
      <section>
        <h1>{name}</h1>
        <Tag company={CURRENT.COMPANY} href={CURRENT.PRODUCT_PAGE} />
        <Links links={socials} />
      </section>
      <section>
        <ScrollAppear direction="up">
          <Excuse />
        </ScrollAppear>
      </section>
    </div>
  );
}
