import React from "react";

import { ScrollAppear } from "../export";
import Introduction from "./introduction";

function Carousel() {
  return (
    <div className="carousel__wrapper">
      <ScrollAppear direction="right">
        <Introduction />
      </ScrollAppear>
      <ScrollAppear direction="left">
        <div className="carousel__div">
          <div>wordle</div>
          <div>maze</div>
          <div>math</div>
        </div>
      </ScrollAppear>
    </div>
  );
}

export default Carousel;
