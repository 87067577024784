import React from "react";

function Introduction() {
  return (
    <>
      <div className="carousel__image" />
      <h2>Hi, my name is Tom.</h2>
      <p>
        I am a self taught developer and <strong>javascript enthusiast</strong>{" "}
        hailing from Sydney Australia.
      </p>
      <p>
        My expertise are in web, front-end specifically but I'm passionate about
        it all. From frontend to back, the API's inbetween; tinkering with
        different languages or building horrible games, I love all of it.
      </p>
      <p>
        The title page <strong>above contains</strong> a few notable links to
        get to know me. <strong>Github</strong> for technical projects, be them
        archived or live, you'll find them all there. <strong> Linkedin</strong>{" "}
        of course for more detailed work history and <strong>Soundcloud</strong>
        , which is honestly more of an audio diary then anything else, it's just
        me trying share some of my more vulnerable thoughts on a guitar, but hey
        maybe you'll enjoy it too.
      </p>
      <p>
        Since you've made it all this way enjoy a game or too, built by yours
        truly. <span>&#9996;</span>
      </p>
    </>
  );
}

export default Introduction;
